import React from "react"
import { Modal } from "@redq/reuse-modal"
import { graphql, useStaticQuery } from "gatsby"

import ChangeYourLife from "../containers/ChangeYourLife"
import Layout from "../containers/Layout"
import PageTitle from "../containers/PageTitle"
import SEO from "../components/SEO"
import Testimonial from "../containers/Testimonial"
import Treatments from "../containers/Treatments"
import TreatmentsSelect from "../containers/TreatmentsSelect"
import TreatmentsStories from "../containers/TreatmentsStories"
import VideoSection from "../containers/VideoSection"
import { ContentWrapper } from "../containers/elegant.style"

const Health = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulTreatments(
        filter: { treatmentCategory: { eq: "health" } }
        sort: { fields: updatedAt, order: DESC }
      ) {
        edges {
          node {
            treatmentTitle
            treatmentDescription {
              treatmentDescription
            }
            treatmentThumbnailImage {
              fluid {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            slug
            treatmentCategory
          }
        }
      }
      featureImage: file(
        relativePath: { eq: "image/elegant/health-preview-video.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      treatmentsStoriesImage: file(
        relativePath: { eq: "image/elegant/treatmentsHealth/Heart.png" }
      ) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      elegantJson {
        healthTestimonial {
          text
          name
          image {
            childImageSharp {
              fluid(quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        treatmentsHealthStories {
          image {
            childImageSharp {
              fluid(quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          title
          description
        }
        treatmentsHealthIntro {
          title
          image {
            childImageSharp {
              fluid(quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  const url = location.href ? location.href : ""

  return (
    <>
      <SEO
        url={url}
        title="Private Health Treatments in Bournemouth & Poole"
        shortDesc="Elegant Beauty Clinic"
        description="We offer a number of private healthcare services to help you understand your body and what it needs. We will help you build a clear picture of your current level of health."
      />
      <Layout>
        <Modal />
        <ContentWrapper>
          <PageTitle
            title="Private Healthcare Services"
            subtitle="Elegant Health"
          />
          <TreatmentsSelect
            treatmentsData={data.allContentfulTreatments}
            category="health"
          />
          <VideoSection
            placeholder={data.featureImage}
            videoUrl="https://www.youtube.com/embed/_MZ4IG-vvzk?&theme=dark&autohide=2&autoplay=1&modestbranding=1&showinfo=0&showsearch=0&rel=0&iv_load_policy=3&cc_load_policy=1"
          />
          <Treatments
            introContent={data.elegantJson.treatmentsHealthIntro}
            iconBigger
            treatmentsData={data.allContentfulTreatments}
          />
          <Testimonial
            testimonialContent={data.elegantJson.healthTestimonial}
            videoUrl="https://www.youtube.com/embed/VwAIrZZc9SA"
          />
          <TreatmentsStories
            treatmentsContent={data.elegantJson.treatmentsHealthStories}
            treatmentsStoriesImage={data.treatmentsStoriesImage}
            imageRotate
          />
          <ChangeYourLife
            title="Ready to start your journey with Elegant Health?"
            text="We’re here to help you feel your best. Explore our services, get in touch and book your healthcare consultation today."
          />
        </ContentWrapper>
      </Layout>
    </>
  )
}

export default Health
